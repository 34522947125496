import axiosInstance from "../axios";
import { ApiResponse } from "../../types/api";
import { HrisStatus, Provider } from "../../types/hris";

class HrisService {
  getStatus(): Promise<ApiResponse<HrisStatus>> {
    return axiosInstance.get(`/sync/ipaashris/status`);
  }

  getProviders(): Promise<ApiResponse<Provider[]>>  {
    return axiosInstance.get(`/sync/ipaashris/providers`);
  }

  getClientId(): Promise<ApiResponse<{id:string}>>  {
    return axiosInstance.get(`/sync/ipaashris/client_id`);
  }

  disconnect() {
    return axiosInstance.post(`/sync/ipaashris/disconnect`);
  }

  connect(code: any) {
    return axiosInstance.post(`/sync/ipaashris/access_token`, { code });
  }

  configure() {
    return axiosInstance.post(`   /sync/ipaashris/configuration`);
  }
}

export const hrisService = new HrisService();
