import {
  Bounce,
  toast as reactToastify,
  ToastOptions,
  TypeOptions,
} from "react-toastify";

export const toast = (
  notice: string,
  type?: TypeOptions,
  options?: ToastOptions,
) => {
  const config: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    ...options,
  };

  switch (type) {
    case "success":
      reactToastify.success(notice, config);
      break;
    case "error":
      reactToastify.error(notice, config);
      break;
    case "info":
      reactToastify.info(notice, config);
      break;
    case "warning":
      reactToastify.warning(notice, config);
      break;
    default:
      reactToastify(notice, config);
      break;
  }
};
