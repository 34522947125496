import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCloudArrowDown,
  faEdit,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import "./documenttable.css";
import { formatDate, formatName } from "../../../utils/helperfunctions";
import { Link } from "react-router-dom";
import { IDocument, ParentType } from "../../../types/documents";
import { useAuth } from "../../../api/auth";
import { DocumentsOrderBy } from "../documents";
import SelectAutocomplete from "../../../components/common/inputs/autocompleteselect";
import { Option } from "../../../types/misc";
import { Input } from "@mui/material";

interface DocumentsTableProps {
  parentId: string;
  documents: IDocument[];
  parentType: ParentType;
  categoryOptions: Option[];
  onDelete: (id: string, filename:string) => void;
  onDownload: (id: string) => void;
  onEdit: (id: string, fileName: string, cagtegoryId: number) => void;
  onSort: (order_by: DocumentsOrderBy, asc: "true" | "false") => void;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({
  categoryOptions,
  parentId,
  documents,
  parentType,
  onDelete,
  onDownload,
  onSort,
  onEdit,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userLogin = auth.getUser();
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [canView, setCanView] = useState<boolean>(true);
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<DocumentsOrderBy>("created_at");
  const [asc, setAsc] = useState<"false" | "true">("false");
  const [editCategoryId, setEditCategoryId] = useState<string>("");
  const [editFileName, setEditFileName] = useState<string>("");
  const [editId, setEditId] = useState<number>();

  useEffect(() => {
    if (parentType === ParentType.JOB) {
      setCanDelete(userLogin.permissions.includes("plan_delete_documents"));
      setCanView(userLogin.permissions.includes("plan_view_documents"));
      setCanEdit(userLogin.permissions.includes("plan_upload_documents"));
    } else {
      setCanDelete(
        userLogin.permissions.includes("person_delete_documents") ||
        ((userLogin.permissions.includes("my_delete_my_uploaded_documents") ||
            userLogin.permissions.includes("my_delete_any_documents")) &&
            userLogin.id === parentId)
      );
      setCanEdit(
        userLogin.permissions.includes("person_upload_documents") ||
        (userLogin.permissions.includes("my_upload_documents") &&
            userLogin.id === parentId)
      );
      setCanView(
        userLogin.permissions.includes("person_view_documents") ||
          ((userLogin.permissions.includes("my_view_my_uploaded_documents") ||
            userLogin.permissions.includes("my_view_all_documents")) &&
            userLogin.id === parentId)
      );
    }
  }, [userLogin]);

  const handleSort = (sortBy: DocumentsOrderBy, asc: "true" | "false") => {
    onSort(sortBy, asc);
    setOrderBy(sortBy);
    setAsc(asc);
  };

  const toggleEdit = (document?: IDocument) => {
    if (!document) {
      setEditId(undefined);
      setEditCategoryId("");
      setEditFileName("");
      return;
    }
    setEditId(document.id);
    setEditCategoryId(document.category?.id.toString() || "");
    setEditFileName(document.file_name);
  };

  const onSave = () => {
    if (!editId || !editFileName || !editCategoryId) return;

    onEdit(editId.toString(), editFileName, Number(editCategoryId));
  };

  return (
    <>
      {documents?.length > 0 ? (
        <div className="documents-table">
          <div className="header-row">
            <div className="cell flex">
              {t("document.table.name.header")}
              <div className="arrow-container">
                <FontAwesomeIcon
                  onClick={() => handleSort("file_name", "true")}
                  className={
                    orderBy === "file_name" && asc === "true"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  onClick={() => handleSort("file_name", "false")}
                  className={
                    orderBy === "file_name" && asc === "false"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretDown}
                />
              </div>
            </div>
            <div className="cell flex">
              {t("document.table.category.header")}
              <div className="arrow-container ">
                <FontAwesomeIcon
                  onClick={() => handleSort("category", "true")}
                  className={
                    orderBy === "category" && asc === "true"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  onClick={() => handleSort("category", "false")}
                  className={
                    orderBy === "category" && asc === "false"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretDown}
                />
              </div>
            </div>

            <div className="cell flex">
              {t("document.table.uploaded.header")}
              <div className="arrow-container">
                <FontAwesomeIcon
                  onClick={() => handleSort("creator", "true")}
                  className={
                    orderBy === "creator" && asc === "true"
                      ? "purple icon flextable-headercell-sort-up"
                      : "light-gray icon flextable-headercell-sort-up"
                  }
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  onClick={() => handleSort("creator", "false")}
                  className={
                    orderBy === "creator" && asc === "false"
                      ? "purple icon flextable-headercell-sort-down"
                      : "light-gray icon flextable-headercell-sort-down"
                  }
                  icon={faCaretDown}
                />
              </div>
            </div>
            <div className="cell flex">
              {t("document.table.created.header")}
              <div className="arrow-container">
                <FontAwesomeIcon
                  onClick={() => handleSort("created_at", "true")}
                  className={
                    orderBy === "created_at" && asc === "true"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  onClick={() => handleSort("created_at", "false")}
                  className={
                    orderBy === "created_at" && asc === "false"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretDown}
                />
              </div>
            </div>
            <div className="cell flex">
              {t("document.table.type.header")}
              <div className="arrow-container">
                
                <FontAwesomeIcon
                  onClick={() => handleSort("file_type", "true")}
                  className={
                    orderBy === "file_type" && asc === "true"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretUp}
                />
                <FontAwesomeIcon
                  onClick={() => handleSort("file_type", "false")}
                  className={
                    orderBy === "file_type" && asc === "false"
                      ? "purple icon"
                      : "light-gray icon"
                  }
                  icon={faCaretDown}
                />
              </div>
            </div>
            <div className="cell">{t("document.table.options.header")} </div>
          </div>
          {documents.map((document) => (
            <div className="row" key={document.id || ""}>
              {editId && editId === document.id ? (
                <div className="cell">
                  <Input
                    sx={{
                      backgroundColor: "white",
                      height: "40px",
                    }}
                    value={editFileName}
                    onChange={(e) => setEditFileName(e.target.value)}
                  />
                </div>
              ) : (
                <div className="cell">{document.file_name || ""}</div>
              )}

              {editId && editId === document.id ? (
                <div className="cell">
                  <SelectAutocomplete
                    backgroundColor="white"
                    value={editCategoryId}
                    options={categoryOptions}
                    onChange={(option) => setEditCategoryId(option.value)}
                  />
                </div>
              ) : (
                <div className="cell">{document.category?.name || ""}</div>
              )}

              <div className="cell">
                <p className="cursor-pointer">
                  <Link to={`/person/${document.creator?.id}`}>
                    {formatName(
                      document.creator?.fname,
                      document.creator?.lname
                    ) || ""}
                  </Link>
                </p>
              </div>

              <div className="cell">
                {formatDate(document.created_at) || ""}
              </div>
              <div className="cell">
                {document.file_type?.toUpperCase() || ""}
              </div>
              <div className="cell options-cell">
                {editId === undefined && (
                  <FontAwesomeIcon
                    className="icon "
                    icon={faCloudArrowDown}
                    onClick={() => onDownload(document.id.toString())}
                  />
                )}
                {((parentType == ParentType.JOB && canDelete) ||
                    canEdit) && (
                  <>
                    {editId === undefined && canDelete && (
                      <FontAwesomeIcon
                        className="icon red"
                        icon={faTrash}
                        onClick={() => onDelete(document.id.toString(), document.file_name)}
                      />
                    )}
                    {editId === undefined && canEdit &&  (
                      <FontAwesomeIcon
                        className="icon"
                        icon={faEdit}
                        onClick={() => toggleEdit(document)}
                      />
                    )}
                    {editId && editId === document.id && (
                      <>
                        <button
                          disabled={editFileName.length < 3}
                          className={`icon ${
                            editFileName.length < 3 ? "gray" : "green"
                          }`}
                          onClick={() => onSave()}>
                          <FontAwesomeIcon icon={faCheck} />
                        </button>
                        <button
                          className="red icon "
                          onClick={() => toggleEdit()}>
                          <FontAwesomeIcon icon={faX} />
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div>
            {canView === false ? t("documents.cannot.view") : t("no.documents")}{" "}
          </div>
        </>
      )}
    </>
  );
};

export default DocumentsTable;
