import { ApiResponse } from "../../types/api";
import {
  DocumentCategory,
  DocumentWithUrl,
  EditDocumentDTO,
  IDocument,
  ParentType,
} from "../../types/documents";
import axiosInstance, { axiosForS3 } from "../axios";

class DocumentsService {
  fetchCategories(): Promise<ApiResponse<DocumentCategory[]>> {
    return axiosInstance.get(`/api/uploads/categories`);
  }

  fetchDocuments(
    parentType: string,
    id: string,
    order_by?: string,
    asc?: string
  ): Promise<ApiResponse<IDocument[]>> {
    order_by = order_by || "created_at";
    asc = asc || "false";
    return axiosInstance.get(
      `/api/uploads/${parentType}/${id}?order_by=${order_by}&asc=${asc}`
    );
  }

  getDownloadUrl(
    downloadId: string,
    parentId: string,
    parentType: string
  ): Promise<ApiResponse<DocumentWithUrl>> {
    return axiosInstance.get(
      `/api/uploads/document/${downloadId}?parent_id=${parentId}&parent_type=${parentType}`
    );
  }

  createNewDocument(
    parentType: string,
    id: string,
    data: any
  ): Promise<ApiResponse<DocumentWithUrl>> {
    return axiosInstance.post(`/api/uploads/${parentType}/${id}`, data);
  }

  deleteDocument(
    deleteId: string,
    parentId: string,
    parentType: string
  ): Promise<ApiResponse<IDocument>> {
    return axiosInstance.delete(
      `/api/uploads/document/${deleteId}?parent_id=${parentId}&parent_type=${parentType}`
    );
  }

  uploadToS3(
    preSignedUrl: string,
    file: File
  ): Promise<ApiResponse<DocumentWithUrl>> {
    return axiosForS3.put(preSignedUrl, file, {
      headers: {
        "Content-Type": file.type,
      },
      responseType: "document",
    });
  }

  editDocument(params: {
    editId: string;
    parentId: number;
    parentType: ParentType;
    fileName: string;
    categoryId: number;
  }): Promise<ApiResponse<IDocument>> {
    const body: EditDocumentDTO = {
      parent_id: params.parentId,
      parent_type: params.parentType,
      category_id: params.categoryId,
      file_name: params.fileName,
    };

    return axiosInstance.put(`/api/uploads/document/${params.editId}`, body);
  }

  deleteCategory(body: { id: string }) {
    //@ts-ignore
    return axiosInstance.delete(` /api/uploads/category`, body);
  }
  createCategory(body: any) {
    return axiosInstance.post(` /api/uploads/category`, body);
  }

  orderCategories(body: { category_ids: string[] }) {
    return axiosInstance.put(`  /api/uploads/category/order`, body);
  }

  updateDefaultDocumentCategory(type: "job" | "person", body: { id: string }) {
    let url =
      type === "job"
        ? "/api/uploads/category/default/job"
        : "/api/uploads/category/default/person";
    return axiosInstance.put(url, body);
  }
}

export const documentsService = new DocumentsService();
