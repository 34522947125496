import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./autocomplete.css";
import { styled, lighten, darken } from "@mui/system";

export interface Option {
  value: string;
  label: string;
}

interface SelectAutocompleteProps {
  options: readonly Option[];
  label?: string;
  value?: string;
  allowEmptySelection?: boolean;
  width?: string;
  placeholder?: string;
  /**
   * allows gray or white - to be updated with more options
   */
  backgroundColor?: "gray" | "white";
  groupAllOptions?: boolean;
  /**
   * disables all options if set to true, allows for more of a guide than functional select
   */
  disableAllOptions?: boolean;
  /**
   * String text. Header of the group when all options are grouped together. designed to be used with the disableAllOptions = true.
   */
  groupAllOptionsLabel?: string;
  disabled?: boolean;
  onChange: (newValue: any | null) => void;
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string,
    reason: string
  ) => void;
  onKeyDown?: (event: React.SyntheticEvent) => void;
  groupBy?: (option: Option) => string;
}

const SelectAutocomplete: React.FC<SelectAutocompleteProps> = ({
  disabled = false,
  options,
  label,
  value = "",
  width = "100%",
  onChange,
  onInputChange,
  onKeyDown,
  groupBy,
  allowEmptySelection = false,
  placeholder = "",
  backgroundColor = "white",
  groupAllOptions = false,
  disableAllOptions = false,
  groupAllOptionsLabel,
}) => {
  const selectedOption =
    options.find((option) => option.value === value) || null;

  const handleChange = (event: React.SyntheticEvent, newValue: any | null) => {
    onChange(newValue ? newValue : null);
  };

  const handleInputChange = (
    e: React.SyntheticEvent<Element, Event>,
    nv: string,
    r: string
  ) => {
    if (onInputChange) {
      onInputChange(e, nv, r);
    }
  };
  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (onKeyDown && e.key === "Enter") {
      e.preventDefault();
      onKeyDown(e);
    }
  };

  const colorMap = {
    gray: "#EEEEEE",
    white: "#FFFFFF",
  };

  const groupOptions = (option: Option): string => {
    if (groupAllOptions) {
      return groupAllOptionsLabel || "All";
    }

    if (groupBy) {
      return groupBy(option);
    }
    return option.label;
  };
  //https://mui.com/material-ui/react-autocomplete/
  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
  }));

  const GroupItems = styled("ul")({
    padding: 0,
  });
  return (
    <>
      {label && (
        <label className="autocomplete-label">{label.toUpperCase()}</label>
      )}
      <Autocomplete
      disabled={disabled}
        sx={{
          width: `${width}`,
          "& .MuiInputBase-root": {
            height: "40px",
            backgroundColor: "#FFFFFF"
          },
          "& .MuiInputBase-input": {
            transform: "translate(0px, -8px)",
          },
        }}
        groupBy={(option) => groupOptions(option)}
        value={selectedOption}
        onInputChange={(e, nv, r) => handleInputChange(e, nv, r)}
        onChange={handleChange}
        options={options}
        getOptionLabel={(option: any) =>
          typeof option === "string" ? option : option.label
        }
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} />
        )}
        getOptionDisabled={(option) => disableAllOptions}
        renderGroup={(params) =>
          groupAllOptions || groupBy ? (
            <li key={params.key}>
              <GroupHeader>{groupAllOptionsLabel}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          ) : (
            <GroupItems key={params.key}>{params.children}</GroupItems>
          )
        }
        disableClearable={!allowEmptySelection}
        freeSolo={allowEmptySelection}
        onKeyDown={handleOnKeyDown}
      />
    </>
  );
};

export default SelectAutocomplete;
