import React from "react";

export type IconProps = {
  size?: "sm" | "md" | "lg";
  color?: "purple" | "green" | "black";
};

const SendIconSVG: React.FC<IconProps> = ({ size = "sm", color = "purple" }) => {
  const sizes = {
    sm: { width: "21", height: "20" },
    md: { width: "42", height: "40" },
    lg: { width: "63", height: "60" },
  };

  const colors = {
    purple: "#7860DC",
    green: "#44a896",
    black: "#000000",
  };

  const { width, height } = sizes[size];
  const fillColor = colors[color];

  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.740234 0.252075L2.10524 9.8071L2.87699 10L2.10524 10.193L0.740234 19.748L20.2362 10L0.740234 0.252075ZM3.89495 8.19298L3.25996 3.748L15.764 10L3.25996 16.2521L3.89495 11.8071L11.1232 10L3.89495 8.19298Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SendIconSVG;