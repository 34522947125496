import axios from "axios";
import { authStrategy } from "..";

export const axiosForS3 = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosInstance = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});



// add auth token to each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = authStrategy.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


export default axiosInstance;
