

export interface DocumentCategory {
  created_at: string;
  deleted_at: string | null;
  id: number;
  is_universal: boolean;
  name: string;
  org_id: number | null;
  priority: number | null;
  slug: string;
  updated_at: string | null;
}

export interface FetchCategoriesReturn  {
  data: DocumentCategory[];
}

export enum ParentType {
  JOB = "job",
  PERSON = "user",
}

export interface IDocument {
  category: DocumentCategory;
  created_at: string;
  created_by?: number;
  deleted_at: string | null;
  file_name: string;
  file_size: number;
  file_type: string;
  id: number;
  org_id: number;
  s3_bucket: string;
  s3_key: string;
  s3_region: string;
  updated_at: string | null;
  creator: Creator;
}

export interface DocumentWithUrl {
  document: IDocument;
  url: string;
}

//partial User
interface Creator {
  id: number;
  fname: string;
  lname: string;
  org_id: number;
  email: string;
  fl_archive: string;
}

export interface EditDocumentDTO {
  parent_id: number;
  parent_type: ParentType;
  file_name: string;
  category_id?: number;
}
