import { ApiResponse } from "../../types/api";
import { AddPeopleToPlanDTO } from "../../types/job";
import {
  SendInviteToPlanDTO,
  SendManagerSurveyDTO,
  SendSurveyDTO,
  SendSurveyPlanAndUsersDTO,
} from "../../types/messages";
import axiosInstance from "../axios";

//TODO clean up messages service BE
class MessagesService {
  enableLogin(body: {
    users: Array<{ id: string }>;
  }): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/enablelogin", body);
  }
  disableLogin(body: {
    users: Array<{ id: string }>;
  }): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/disablelogin", body);
  }

  sendSurveyReminder(body: SendSurveyDTO): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/sendreminder", body);
  }

  sendSurveyReminderWithUsers(body: any): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/sendreminderwithusers", body);
  }

  sendSurveyReminderWithAPlanAndUsers(
    body: SendSurveyPlanAndUsersDTO
  ): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/sendreminderwithaplanandusers", body);
  }

  sendSurveyReminderWithPlans(body: any): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/sendreminderwithplans", body);
  }

  //TODO look into if this is the same as above or below
  //TODO this returns 201 ok but did not receive email
  sendCompleteSurvey(body: {
    userId: string;
    planId: string;
  }): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/completesurvey", body);
  }

  sendManagerSurveyReminder(
    body: SendManagerSurveyDTO
  ): Promise<ApiResponse<undefined>> {
    return axiosInstance.post("/api/message/remindmanagerscompletesurvey", body);
  }

  inviteToPlan(body: SendInviteToPlanDTO): Promise<ApiResponse<any>> {
    return axiosInstance.post("/api/message/invitetoplan", body);
  }

  resendWelcomeEmail(body: { userIds: string[] }): Promise<ApiResponse<any>> {
    return axiosInstance.post("/api/message/resendWelcome", body);
  }

  addPeopleToPlan(body: AddPeopleToPlanDTO): Promise<ApiResponse<{results: {userId:string, status: string}[],skipped:number, added:number}>> {
    return axiosInstance.post("/api/message/addpeopletoplan", {
      users: body.users,
      invitationEmail: body.invitationEmail,
      surveyManager: body.surveyManager,
    });
  }
  //TODO this seems like it's in the wrong place. 
  sendRemoveSurvey(body: any): Promise<ApiResponse<any>> {
    return axiosInstance.put("/api/jobapplicant/remove/removeSurvey", body);
  }
}

export const messagesService = new MessagesService();
