import { ApiResponse } from "../../types/api";
import { SmeDefinition } from "../../types/organization";
import axiosInstance from "../axios";

class SmeService {
  getSmeDefinitions(): Promise<ApiResponse<SmeDefinition[]>> {
    return axiosInstance.get("/api/smedefinitions");
  }

  updateSmeDefinition(id: string, body: SmeDefinition) {
    return axiosInstance.put(`/api/smedefinitions/${id}`,body);
  }

  updateDefaultSmeDefinition(body: {definition_id:string}) {
    return axiosInstance.put(`/api/smedefinitions/default`, body);
  }
}

export const smeService = new SmeService();
