import { ApiResponse } from "../../types/api";
import { Industry, OktaConfig } from "../../types/organization";
import axiosInstance from "../axios";

class OrganizationService {
  fetchOrganization(orgId: string) {
    return axiosInstance.get(`/api/organization/${orgId}`);
  }

  updateOrganization(orgId: string, body: any) {
    return axiosInstance.put(`/api/organization/${orgId}`, body);
  }

  getIndustries(): Promise<ApiResponse<Industry[]>> {
    return axiosInstance.get(`/api/industries`);
  }

  getIdpProviders(): Promise<ApiResponse<string[]>> {
    return axiosInstance.get(`/api/organization/data/sso_providers`);
  }

  getIdp(): Promise<ApiResponse<{ provider: string | null }>> {
    return axiosInstance.get(`/api/organization/idp`);
  }

  updateIdpProvider(idp: string): Promise<ApiResponse<string[]>> {
    return axiosInstance.patch(`/api/organization/idp`, { idp });
  }

  getOktaConfig(
    includeCredentials: boolean = false
  ): Promise<ApiResponse<OktaConfig>> {
    let query = includeCredentials ? `?include_credentials=true` : "";
    return axiosInstance.get(`/api/organization/okta_credentials${query}`);
  }

  updateOktaConfig(config: OktaConfig): Promise<ApiResponse<OktaConfig>> {
    return axiosInstance.patch(`/api/organization/okta_credentials`, config);
  }
}

export const organizationService = new OrganizationService();
