import { ApiResponse } from "../../types/api";
import { CreateRoleDto, RolesModel } from "../../types/user";
import axiosInstance from "../axios";

class RolesService {
  getRoles(): Promise<ApiResponse<RolesModel[]>> {
    return axiosInstance.get(`/api/roles`);
  }

  updateRole(id: string, body: any) {
    return axiosInstance.put(`/api/roles/${id}`, body);
  }

  setDefaultRole(id: string) {
    return axiosInstance.patch(`/api/roles/default`, { role_id: id });
  }

  updateRoles(body: any) {
    return axiosInstance.put(`/api/roles/roleList/update`, body);
  }


  fetchRolesForUsers(userIds?: string[]): Promise<ApiResponse<RolesModel[]>> {
    const params = userIds?.length ? { userIds: userIds.join(",") } : {};
    return axiosInstance.get(`/api/roles/get/roleList`, { params });
  }

  //TODO: confirm this duplicates getRoles
  fetchRoles(): Promise<ApiResponse<RolesModel[]>> {
    return axiosInstance.get(`/api/roles/get/roleList`);
  }

  getRolesById(id: string): Promise<ApiResponse<RolesModel[]>> {
    return axiosInstance.get(`/api/roles/${id}`);
  }

  deleteRole(id: string) {
    return axiosInstance.delete(`/api/roles/${id}`);
  }

  createRole(body: CreateRoleDto): Promise<ApiResponse<RolesModel>> {
    return axiosInstance.post(`/api/roles`, body);
  }
}

export const rolesService = new RolesService();
