
import axiosInstance from "../axios";
import { ApiResponse } from "../../types/api";

class AuthService {
  sendResetPasswordLink(body:{email:string}) {
    return axiosInstance.post(`/api/passwordrecovery`,body);
  }

  passwordRecovery(id: string, body: {password:string}) {
    return axiosInstance.post(`/api/passwordrecovery/changepassword/${id}`, body);
  }

  resetPasswordWithToken(token: any, password:string) {
    return axiosInstance.post(`/api/passwordrecovery/resetpassword/${token}`, {password: password});
  }

  verifyToken(token: any) {
    return axiosInstance.get(`/api/passwordrecovery/${token}`);
  }

  getLockedStatus(email: string): Promise<ApiResponse<{ locked: boolean }>> {
    return axiosInstance.get(`/api/authenticate/locked_status/${email}`);
  }

  deleteLockedStatus(id: string) {
    return axiosInstance.delete(`/api/authenticate/lock/${id}`);
  }

  getMfaStatusByEmail(email: string): Promise<ApiResponse<{ mfa_active: boolean }>> {
    return axiosInstance.get(`/api/authenticate/totp_check/${email}`);
  }

  deleteMfaStatus(user_id: string) {
    return axiosInstance.delete(`/api/authenticate/user_totp/${user_id}`);
  }

  seedMfa() : Promise<ApiResponse<{ uri: string }>> {
    return axiosInstance.get(`/api/authenticate/totp_seed`);
  }

  deleteMfaFactor() {
    return axiosInstance.delete(`/api/authenticate/totp`);
  }
  getMfaStatus() : Promise<ApiResponse<  {mfa_active: boolean, org_mfa_module: boolean}>>  {
    return axiosInstance.get(`/api/authenticate/totp_status`);
  }

  verifyMfaSeed(totp:string){
    return axiosInstance.post(`/api/authenticate/totp_verify`,totp);
  }
}

export const authService = new AuthService();
