import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { FC } from "react";
import { BRAND_GREEN, BRAND_RED, BRAND_YELLOW } from "../../../utils/constants";

interface VizopDoughnutProps {
  partiallyMeetsTarget: number;
  meetsTarget: number;
  score: number;
}
const VizopDoughnut: FC<VizopDoughnutProps> = ({
  score,
  partiallyMeetsTarget,
  meetsTarget,
}) => {
  const meetsTargetColor = BRAND_GREEN;
  const partiallyMeetsTargetColor = BRAND_YELLOW;
  const doesNotMeetTargetColor = BRAND_RED;
  const options = {
    chart: {
      height: 100,
      width: 150,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      type: "pie",
      spacingTop: 0,
      spacingRight: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      marginTop: 0,
      groupPadding: 0,
      padding: [0, 0, 0, 0],
      margin: [0, 0, 0, 0],
    },
    title: {
      text: `${score}%`,
      align: "center",
      verticalAlign: "middle",
      y: 22,
      style: {
        fontSize: "16px",
      },
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        startAngle: -90,
        endAngle: 90,
        center: ["50%", "75%"],
        size: "150%",
      },
    },
    series: [
      {
        type: "pie",
        innerSize: "50%",
        data: [
          {
            y: score,
            color:
              score >= meetsTarget
                ? meetsTargetColor
                : score >= partiallyMeetsTarget
                ? partiallyMeetsTargetColor
                : doesNotMeetTargetColor,
          },
          {
            y: 100 - score,
            color: "#e0e0e0",
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default VizopDoughnut;
