import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileUpload } from "../../components/common/fileupload/fileupload";
import { useEffect, useRef, useState } from "react";
import SelectAutocomplete from "../../components/common/inputs/autocompleteselect";
import BasicButton from "../../components/common/buttons/basicbutton";
import { useTranslation } from "react-i18next";
import { DocumentCategory, ParentType } from "../../types/documents";
import { useAuth } from "../../api/auth";
import { Option } from "../../types/misc";
import DocumentsTable from "./documenttable/documenttable";
import { PageLoading } from "../../components/pageloading";
import { documentsService } from "../../api/services/documents.service";
import { organizationService } from "../../api/services/organization.service";
import ConfirmDialog from "../../components/confirmdialog";
import { MAX_DOCUMENT_FILE_SIZE_ALLOWED } from "../../utils/constants";
import { order } from "../../utils/orders";

type DocumentComponentProps = {
  id: string;
  parentType: ParentType;
};

export type DocumentsOrderBy =
  | "created_at"
  | "file_name"
  | "updated_at"
  | "file_type"
  | "creator"
  | "category";

export const DocumentsComponent: React.FC<DocumentComponentProps> = ({
  parentType,
  id,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const userLogin = auth.getUser();
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [documentCategoryId, setDocumentCategoryId] = useState<string>("");
  const [options, setOptions] = useState<Option[]>();
  const [documents, setDocuments] = useState<any[]>();
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteId, setDeleteId] = useState<string>("");
  const [deleteFilename, setDeleteFilename] = useState<string>("");
  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<DocumentsOrderBy>("created_at");
  const [asc, setAsc] = useState<"false" | "true">("false");

  const fileInputRef = useRef<HTMLInputElement>(null);
  const baseError = t("documents.upload.base.error.message");

  useEffect(() => {
    if (parentType === ParentType.JOB) {
      setCanUpload(userLogin.permissions.includes("plan_upload_documents"));
    } else {
      setCanUpload(
        userLogin.permissions.includes("person_upload_documents") ||
          (userLogin.permissions.includes("my_upload_documents") &&
            userLogin.id === id)
      );
    }
  }, [userLogin]);

  useEffect(() => {
    fetchAndSetDocuments();
    fetchAndSetCategories();
    fetchAndSetOrganization();
  }, []);

  const formatOptions = (categories: DocumentCategory[]) => {
    const options = categories?.map((c) => ({
      value: c.id.toString(),
      label: c.name,
    }));
    setOptions(options);
  };

  const handleUpload = () => {
    createNewDocument();
  };

  const createNewDocument = async () => {
    const file: File = filesToUpload[0];
    const data = {
      category_id: Number(documentCategoryId),
      content_type: file.type,
      file_name: file.name,
      file_size: file.size,
      file_type: file.name.split(".").pop(),
    };
    try {
      const response = await documentsService.createNewDocument(
        parentType,
        id,
        data
      );

      let preSignedUrl = response.data.url;
      const awsResponse = await documentsService.uploadToS3(preSignedUrl, file);
      if (awsResponse.status >= 200 && awsResponse.status <= 400) {
        await fetchAndSetDocuments();
      } else {
        console.log("Error putting file to aws, error type:");
      }
    } catch (error: any) {
      console.log(error);
      showErrors(
        [baseError, `Error Message: ${error.response.statusText}`],
        6000
      );
    }
  };

  const cancelDelete = async () => {
    setDeleteId("");
    setDeleteFilename("");
  };

  const handleDelete = async (deleteId: string) => {
    try {
      const response = await documentsService.deleteDocument(
        deleteId,
        id,
        parentType
      );
      if (response.status === 200) {
        await fetchAndSetDocuments();
        setDeleteId("");
        setDeleteFilename("");
      } else {
        console.error(
          `Failed to delete document with ID ${deleteId}:`,
          response.statusText
        );
      }
    } catch (error: any) {
      console.error(`Error deleting document with ID ${deleteId}:`, error);
      showErrors([`Error Message: ${error.response.data.message}`], 6000);
    }
  };

  const deleteDocument = async (deleteId: string, filename: string) => {
    setDeleteFilename(filename);
    setDeleteId(deleteId);
  };

  const fetchAndSetDocuments = async () => {
    setLoading(true);
    try {
      const res = await documentsService.fetchDocuments(parentType, id);
      if (res.data) {
        setDocuments(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
    setLoading(false);
  };

  const reFetchDocuments = async () => {
    try {
      const res = await documentsService.fetchDocuments(
        parentType,
        id,
        orderBy,
        asc
      );
      if (res.data) {
        setDocuments(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
  };

  const fetchAndSetCategories = async () => {
    setLoading(true);
    try {
      const res = await documentsService.fetchCategories();
      if (res.data.length) {
        formatOptions(res.data);
      }
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
    setLoading(false);
  };

  const fetchAndSetOrganization = async () => {
    setLoading(true);
    try {
      const res = await organizationService.fetchOrganization(
        userLogin.orgId.toString()
      );
      const defaultCategoryId =
        parentType === ParentType.PERSON
          ? res.data.doc_default_person_category
          : res.data.doc_default_job_category;
      setDocumentCategoryId(defaultCategoryId.toString());
    } catch (error) {
      console.error("Failed to fetch organization:", error);
    }
    setLoading(false);
  };

  const handleDownload = async (downloadId: string) => {
    try {
      const res = await documentsService.getDownloadUrl(
        downloadId,
        id,
        parentType
      );
      if (res.status === 200) {
        const url = res.data.url;
        const filename = `${res.data.document.file_name}`;
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error: any) {
      showErrors(
        [
          `There was an error downloading the file with id: ${downloadId}`,
          `Error Message: ${error.response.data.message}`,
        ],
        5000
      );
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxSizeBytes = MAX_DOCUMENT_FILE_SIZE_ALLOWED * 1024 * 1024; // Convert maxSize to bytes

    if (!e.target.files) return;
    const filesArray = Array.from(e.target.files);
    const file = filesArray[0];

    if (file && file.size > maxSizeBytes) {
      showErrors(
        [`Files must be ${MAX_DOCUMENT_FILE_SIZE_ALLOWED}MB or smaller`],
        4000
      );
      return;
    }

    setFilesToUpload(filesArray);
  };

  const showErrors = (errors: string[], timeout: number) => {
    if (!errors.length) {
      setErrors([baseError]);
    } else {
      setErrors(errors);
    }

    setTimeout(() => setErrors([]), timeout);
  };

  useEffect(() => {
    (async () => {
      await reFetchDocuments();
    })();
  }, [orderBy, asc]);

  const handleEditDocument = async (
    editId: string,
    fileName: string,
    categoryId: number
  ) => {
    setLoading(true);
    try {
      const params = {
        editId: editId,
        parentId: Number(id),
        parentType: parentType,
        fileName: fileName,
        categoryId: categoryId,
      };
      const res = await documentsService.editDocument(params);
      if (res.status === 200) {
        await reFetchDocuments();
      }
    } catch (error: any) {
      showErrors(
        [
          `There was an editing the file`,
          `Error Message: ${error.response.data.message}`,
        ],
        5000
      );
    }
    setLoading(false);
  };
  return (
    <>
      {loading || !options || !id || !parentType || !userLogin ? (
        <>
          <PageLoading />
        </>
      ) : (
        <div className="page-wrapper">
          {canUpload && (
            <>
              <div className="upload-box">
                {filesToUpload.length === 0 ? (
                  <FileUpload onUpload={(e) => setFilesToUpload(e)}>
                    <>
                      <div className="area-icon">
                        <FontAwesomeIcon icon="cloud-arrow-up" />
                      </div>
                      <p>{t("documents.upload.message")}</p>
                    </>
                  </FileUpload>
                ) : (
                  <>
                    <div className="uploaded-items">
                      <div className="item">
                        <p className="label">{filesToUpload[0]?.name}</p>
                      </div>
                      <div className="item">
                        {" "}
                        <input
                          ref={fileInputRef}
                          type="file"
                          multiple={false}
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                          accept=".doc,.docx,.pdf"
                        />
                        <BasicButton
                          color="white"
                          onClick={() => fileInputRef.current?.click()}>
                          {t("documents.change.file")}
                        </BasicButton>
                      </div>
                      <div className="item label">
                        {t("documents.category")}
                      </div>
                      <div className="item flex gap-4 ">
                        <SelectAutocomplete
                          value={documentCategoryId}
                          options={options || []}
                          onChange={(option) =>
                            setDocumentCategoryId(option.value)
                          }
                        />
                        <div>
                          <BasicButton
                            onClick={() => handleUpload()}
                            disabled={
                              !documentCategoryId || !filesToUpload.length
                            }>
                            {t("documents.upload")}
                          </BasicButton>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {errors.length > 0 && (
                <div className="errors-wrapper">
                  <div className="errors-container">
                    {errors.map((error, i) => {
                      return (
                        <p key={i} className="error-text">
                          {error}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="page-divider"></div>
            </>
          )}

          <DocumentsTable
            onSort={(orderBy: DocumentsOrderBy, asc: "true" | "false") => {
              setOrderBy(orderBy);
              setAsc(asc);
            }}
            parentId={id}
            parentType={parentType}
            documents={documents || []}
            onDelete={(id: string, filename: string) =>
              deleteDocument(id, filename)
            }
            onDownload={(id) => handleDownload(id)}
            categoryOptions={options}
            onEdit={(id, fileName, categoryId) =>
              handleEditDocument(id, fileName, categoryId)
            }
          />
        </div>
      )}
      <ConfirmDialog
        id="doc-delete-dialog"
        displayed={deleteId !== "" && deleteFilename !== ""}
        titleMessage="delete.document.dialog.title"
        bodyMessage={"delete.document.dialog.body"}
        parameters={[deleteFilename]}
        cancelButtonMessage="common.cancel"
        confirmButtonMessage="common.delete"
        onConfirm={() => handleDelete(deleteId)}
        onCancel={() => cancelDelete()}
      />
    </>
  );
};
