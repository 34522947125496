import React from "react";
import "./basicbutton.css";

interface BasicButtonProps {
  children: any;
  onClick: (() => void | ((e:any)=> void));
  disabled?: boolean;
  /**
   * sm , md , or lg allowd as sizes
   */
  size?: "sm" | "md" | "lg";
  color?: "white";
}

const BasicButton: React.FC<BasicButtonProps> = ({
  children,
  size = "sm",
  disabled = false,
  onClick,
  color
}) => {
  return (
    <div
      className={"button" +" " +`${disabled ? "disabled" : ""}` +" "  + `${size}` +" " +`${color ? color : ""}`}
      onClick={() => onClick()}>
      <div>{children}</div>
    </div>
  );
};

export default BasicButton;
