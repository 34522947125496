import React, { useState, ReactNode } from "react";
import "./fileupload.css";

type FileUploadProps = {
  onUpload: (files: File[]) => void;
  count?: number;
  formats?: string[];
    /**
   * maxSize size is a number in MB . if none set, default is 5MB
   */
  maxSize?:number;
  children: ReactNode;
};
export const FileUpload: React.FC<FileUploadProps> = ({
  onUpload,
  children,
  count = 1,
  formats = [".doc", ".docx", ".pdf"],
  maxSize = 5,
}) => {
  const drag = React.useRef<HTMLDivElement>(null);
  const drop = React.useRef<HTMLDivElement>(null);
  const fileInputRef = React.useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [message, setMessage] = React.useState<{
    show: boolean;
    text: string | null;
  }>({
    show: false,
    text: null,
  });

  const showMessage = (text: string, timeout: number) => {
    setMessage({
      show: true,
      text,
    });

    setTimeout(
      () =>
        setMessage({
          show: false,
          text: null,
        }),
      timeout
    );
  };

  React.useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener("dragover", handleDragOver);
      drop.current.addEventListener("drop", handleDrop);
      drop.current.addEventListener("dragenter", handleDragEnter);
      drop.current.addEventListener("dragleave", handleDragLeave);

      return () => {
        if (!drop.current) return;
        drop.current.removeEventListener("dragover", handleDragOver);
        drop.current.removeEventListener("drop", handleDrop);
        drop.current.removeEventListener("dragenter", handleDragEnter);
        drop.current.removeEventListener("dragleave", handleDragLeave);
      };
    }
  }, []);

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target !== drag.current) {
      setDragging(true);
    }
    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const files: File[] = [...e.dataTransfer.files];
    handleFiles(files);
  };

  const openFileBrowser = () => {
    fileInputRef.current?.click();
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? Array.from(e.target.files) : [];
    handleFiles(files);
  };

  const handleFiles = (files: File[]) => {
    setDragging(false);
    if (count && count < files.length) {
      showMessage(
        `Only ${count} file${count !== 1 ? "s" : ""} can be uploaded at a time`,
        4000
      );
      return;
    }
    for(let file of files){
      let maxSizeBytes = maxSize * 1024 * 1024;
    
      if (maxSize && maxSizeBytes < file.size) {
          showMessage(`Files must be ${maxSize}MB or smaller`, 4000);
          return;
      }
    }
    

    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            //@ts-ignore
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      showMessage(
        `Only following file formats are acceptable: ${formats.join(", ")}`,
        4000
      );
      return;
    }
    if (files && files.length) {
      onUpload(files);
    }
  };

  return (
    <div ref={drop} className="drag-drop-file" onClick={openFileBrowser}>
      <input
        //@ts-ignore
        ref={fileInputRef}
        type="file"
        multiple={count > 1}
        onChange={handleFileSelect}
        style={{ display: "none" }}
        accept={formats?.join(", ")}
      />
      <div
        className={
          "drag-drop-file-area" + " " + `${dragging ? "dragging" : ""}`
        }>
        {children}
        {message.show && <span className="error-text">{message.text}</span>}
      </div>
    </div>
  );
};
