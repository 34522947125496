import React, { useEffect, useRef, useState } from "react";
import { ConnectableElement, useDrag, useDrop } from "react-dnd";
import { XYCoord } from "dnd-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faGripLines,
  faTrashCan,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Input, TextField } from "@mui/material";
import type { Identifier } from "dnd-core";

interface DraggableItemProps {
  id: string;
  name?: string;
  index: number;
  canEdit?: boolean;
  draggable?: boolean;
  additionalColumns?: Record<string, any>;
  additionalHeaders?: string[];
  editableField?: string;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  onEdit?: (id: string, newName: string) => void;
  onDelete?: (id: string) => void;
  onDrop?: () => void;
}

interface DragItem {
  type: string;
  id: string;
  index: number;
}

const ItemTypes = {
  ITEM: "item",
};

const DraggableItem: React.FC<DraggableItemProps> = ({
  id,
  name = "",
  index,
  canEdit = true,
  draggable = true,
  additionalColumns,
  additionalHeaders = [],
  editableField,
  moveRow,
  onEdit,
  onDelete,
  onDrop,
}) => {
  const { t } = useTranslation();
  const [edit, setEdit] = useState<boolean>(false);
  const [localName, setLocalName] = useState<string | undefined>(name || "");
  const [editableFieldName, setEditableFieldName] = useState<string>("");

  useEffect(() => {
    if (editableField && additionalColumns) {
      setEditableFieldName(additionalColumns[editableField]);
    }
  }, [additionalColumns]);

  const [, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { id, index } as DragItem,
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (item && didDrop) {
        onDrop?.();
      }
    },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(item: { id: string; index: number; type: string }, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = monitor.getClientOffset() as XYCoord;
      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const handleDrag = (node: ConnectableElement) => {
    if (draggable) {
      drag(drop(node));
    }
  };

  const handleCancel = () => {
    if (name) {
      setLocalName(name);
    }
    if (editableField && additionalColumns && !name) {
      setLocalName(additionalColumns[editableField]);
    }
    setEdit(false);
  };

  const handleSaveEdit = () => {
    if (!onEdit) return;
    if (localName?.length) {
      onEdit(id, localName);
    }

    if (editableFieldName && additionalColumns && !name) {
      onEdit(id, editableFieldName);
    }
    setEdit(false);
  };

  return (
    <>
      {canEdit ? (
        <div className="draggable-row" ref={(node) => handleDrag(node)}>
          {edit ? (
            <>
              {name.length > 0 && (
                <div className="input">
                  <TextField
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "white",
                        height: "35px",
                        paddingLeft: "1rem",
                        marginLeft: "2rem",
                      },
                    }}
                    variant="outlined"
                    className="text-input"
                    value={localName}
                    onChange={(e) => setLocalName(e.target.value)}></TextField>
                </div>
              )}
              {additionalHeaders?.length > 0 && additionalColumns && (
                <>
                  {additionalHeaders.map((h, i) => {
                    if (i === 0) {
                      return (
                        <span className="pl-2">
                          {additionalColumns[h.toLowerCase()]}
                        </span>
                      );
                    }

                    if (h.toLowerCase() === editableField) {
                      return (
                        <div className="input">
                          <TextField
                            sx={{
                              "& .MuiInputBase-root": {
                                backgroundColor: "white",
                                height: "35px",
                                width: "100%",
                              },
                            }}
                            variant="outlined"
                            className="text-input"
                            value={editableFieldName}
                            onChange={(e) =>
                              setEditableFieldName(e.target.value)
                            }></TextField>
                        </div>
                      );
                    }
                    return <div>{additionalColumns[h.toLowerCase()]}</div>;
                  })}
                </>
              )}

              <div>
                {onEdit && (
                  <button
                    className="green icon"
                    onClick={() => handleSaveEdit()}>
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                )}

                <button className="red icon" onClick={() => handleCancel()}>
                  <FontAwesomeIcon icon={faX} />
                </button>
              </div>
            </>
          ) : (
            <>
              {name?.length > 0 && (
                <div className="flex gap-2 items-center">
                  {draggable && (
                    <button className="gray icon">
                      <FontAwesomeIcon icon={faGripLines} />
                    </button>
                  )}

                  <span className="name">{name}</span>
                </div>
              )}
              {additionalHeaders?.length > 0 && additionalColumns && (
                <>
                  {additionalHeaders.map((h, i) => {
                    if (i === 0) {
                      return (
                        <span key={i} className="pl-2">
                          {additionalColumns[h.toLowerCase()]}
                        </span>
                      );
                    }
                    return (
                      <div key={i} className="adjust-left">
                        {additionalColumns[h.toLowerCase()]}
                      </div>
                    );
                  })}
                </>
              )}
              <div>
                {onEdit && (
                  <button className="gray icon" onClick={() => setEdit(true)}>
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                )}
                {onDelete && (
                  <button className="red icon" onClick={() => onDelete(id)}>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="draggable-row">
          <div className="flex gap-2 items-center">
            {additionalHeaders?.length && additionalColumns && (
              <>
                {additionalHeaders.map((h, i) => {
                  if (i === 0) {
                    return (
                      <span key={i} className="pl-2">
                        {additionalColumns[h.toLowerCase()]}
                      </span>
                    );
                  }
                  return <span>{additionalColumns[h.toLowerCase()]}</span>;
                })}
              </>
            )}
            {name && <span className="name">{name}</span>}
          </div>
          <div>{t("admin.universal.category")}</div>
        </div>
      )}
    </>
  );
};

export default DraggableItem;
