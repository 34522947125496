import { ApiResponse } from "../../types/api";
import { BaseManagerProficiency, BaseUserProficiency, UserProficiencyModel } from "../../types/user";

import axiosInstance from "../axios";

class ProficienciesService {
  updateUserProficiencies(body: {
    userProficiency: UserProficiencyModel[];
  }): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/userproficiency`, body);
  }

  updateManagerProficiencies(body:
    {
    managerProficiencies: UserProficiencyModel[];
  }
  ): Promise<ApiResponse<any[]>> {
    return axiosInstance.put(`/api/userproficiencybymanager`, body);
  }

  editOrCreateUserProficiency(body: Partial<BaseUserProficiency>): Promise<ApiResponse<any[]>> {
    return axiosInstance.post(`/api/userproficiency`, body);
  }

  editOrCreateManagerProficiency(body: Partial<BaseManagerProficiency>): Promise<ApiResponse<any[]>> {
    return axiosInstance.post(`/api/userproficiencybymanager`, body);
  }
}

export const proficienciesService = new ProficienciesService();
