import { Option } from '../types/misc';

//colors
export const LIGHT_GRAY = '#eeeeee';
export const MEDIUM_GRAY = '#e0e0e0';
export const BLACK = '#000000';
export const DARK_GRAY = '#515663';
export const BRAND_PURPLE = '#7860dc';
export const BRAND_RED = '#ec483c';
export const BRAND_YELLOW = '#f6b643';
export const BRAND_GREEN = '#44a896';

export const ITEMS_PER_PAGE = 50;

export enum TimeBlock {
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  LAST_MONTH = 'last month',
  LAST_QUARTER = 'last quarter',
  LAST_YEAR = 'last year',
  NEXT_MONTH = 'next month',
  NEXT_QUARTER = 'next quarter',
  NEXT_YEAR = 'next year',
  CUSTOM = 'custom',
}

export const TimeBlockLabelMap: Record<TimeBlock, string> = {
  [TimeBlock.MONTH]: 'This Month',
  [TimeBlock.QUARTER]: 'This Quarter',
  [TimeBlock.YEAR]: 'This Year',
  [TimeBlock.LAST_MONTH]: 'Last Month',
  [TimeBlock.LAST_QUARTER]: 'Last Quarter',
  [TimeBlock.LAST_YEAR]: 'Last Year',
  [TimeBlock.NEXT_MONTH]: 'Next Month',
  [TimeBlock.NEXT_QUARTER]: 'Next Quarter',
  [TimeBlock.NEXT_YEAR]: 'Next Year',
  [TimeBlock.CUSTOM]: 'Custom',
};

export const timeBlockOptions: Option[] = Object.entries(TimeBlock).map(([key, value]) => {
  return {
    value: value as string,
    label: TimeBlockLabelMap[value as TimeBlock],
  };
});
//this number is in MEGABYTES
export const MAX_DOCUMENT_FILE_SIZE_ALLOWED = 5;

//commonly used options for forms
export const SKILL_TYPE_OPTIONS: Option[] = [
  { value: 'B', label: 'common.yesno' },
  { value: 'N', label: 'common.numeric' },
];

export const VISUALIZATION_SETTINGS_FALLBACKS = {
  PARTIALLY_MEETS: '60',
  MEETS_TARGET: '80',
};
