export type NewSkill = {
  name: string;
  priority: number;
  min_level?: string;
  prefered_level?: string;
  org_id: string;
  created_at: Date;
  updated_at: Date;
  //N being numeric and B being "y/n"
  type: "N" | "B";
  skill_category_id: string;
  created_by: string;
  updated_by: string;
  expiration_date?: boolean;
  certified_date?: boolean;
  description?: string;
  sme_only?: "Y" | "N";
  years_of_experience?: "Y" | "N";
};

export type SkillFieldsFromFile = {
  name: string;
  category: string;
  type: string;
  description: string;
  years_of_experience: string;
  expiration_date: string;
  certified_date: string;
};

export enum SkillHeader {
  NAME = "name",
  CATEGORY = "category",
  TYPE = "type",
  DESCRIPTION = "description",
  YOE = "years_of_experience",
  CERT_DATE = "certified_date",
  EXP_DATE = "expiration_date",
}

export const SkillHeaderLabelMap: Record<SkillHeader, string> = {
  [SkillHeader.NAME]: "Skill Name",
  [SkillHeader.CATEGORY]: "Category",
  [SkillHeader.TYPE]: "Type",
  [SkillHeader.DESCRIPTION]: "Description",
  [SkillHeader.YOE]: "Years of Experience",
  [SkillHeader.CERT_DATE]: "Certified Date",
  [SkillHeader.EXP_DATE]: "Expiration Date",
};

export type NewSkillDTO = {
  name: string;
  priority?: number;
  min_level?: string;
  prefered_level?: string;
  org_id: string;
  created_at: Date;
  updated_at?: Date;
  //N being numeric and B being "y/n"
  type?: "N" | "B";
  types?: "N" | "B";
  skill_category_id?: string;
  skill_category: string;
  created_by: Date;
  updated_by: Date;
  expiration_date?: boolean;
  certified_date?: boolean;
  description?: string|null;
  sme_only?: "Y" | "N";
  years_of_experience?: "Y" | "N";
};