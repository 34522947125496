import { useTranslation } from "react-i18next";
import { Provider } from "../../../types/hris";

interface ProviderListItemProps {
    provider: Provider;
  }
export const  ProviderListItem: React.FC<ProviderListItemProps> = (props) => {
    const [t] = useTranslation();

    return (
      <div className="provider-list-item">
        {(props.provider.icon || props.provider.logo) && (
          <img
            src={
              props.provider.icon ? props.provider.icon : props.provider.logo
            }
            alt={props.provider.name}></img>
        )}
        <div className="provider-name">
          <p>
            {props.provider.display_name}{" "}
            <span className="provider-assisted">
              {props.provider.manual == true
                ? t("hris-provider-is-manual.label")
                : null}
            </span>
          </p>
        </div>
      </div>
    );
}



