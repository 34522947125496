import {
  AggregatedSkillMetrics,
  Skill,
  SkillProficiency,
} from "../../../types/skills";

export const NormalizeProficiencies = (
  proficiencyType: "user_proficiencies" | "manager_proficiency",
  proficiencies: SkillProficiency[]
) => {
  switch (proficiencyType) {
    case "user_proficiencies":
      return proficiencies.map((p: SkillProficiency) => ({
        skill_id: p.skill_id,
        sme_score: p.sme_score,
        user_id: p.user_id,
        years_exp: p.years_exp,
      }));
      break;
    case "manager_proficiency":
      return proficiencies.map((p: SkillProficiency) => ({
        skill_id: p.skill_id,
        sme_score: p.sme_score,
        user_id: p.user_id,
        years_exp: p.years_exp,
      }));
      break;
  }
  return [];
};

export const aggregateSkillProficiencies = (
  proficiencies: SkillProficiency[],
  proficiencyType: "manager_proficiency" | "user_proficiencies",
  skills: Skill[],
  maxSme?: number
): AggregatedSkillMetrics => {
  const normalizedProficiencies = NormalizeProficiencies(
    proficiencyType,
    proficiencies
  );
  const proficiencyBucketCutoff = maxSme
    ? [0, maxSme * 0.333333, maxSme * 0.666666, maxSme]
    : [];

  return normalizedProficiencies.reduce(
    (acc: AggregatedSkillMetrics, up: any) => {
      if (up.sme_score >= 0) {
        const skill = skills.find((s: any) => s.id == up.skill_id);
        if (!skill) return acc;
        if (!acc[skill.id]) {
          acc[skill.id] = {
            skill,
            sme_score: 0,
            years_exp: 0,
            yoe_count: 0,
            count: 0,
            totalSurveyed: 0,
            proficiencyRangeScores: {
              novice: [],
              intermediate: [],
              advanced: [],
              expert: [],
            },
          };
        }
        //maxSme is for the bar charts, this data is not needed for treemap
        if (maxSme) {
          const bucketIndex = proficiencyBucketCutoff.findIndex(
            (v) => up.sme_score <= Math.round(v)
          );
          let bucketName = "novice";
          switch (bucketIndex) {
            case 0:
              bucketName = "novice";
              break;
            case 1:
              bucketName = "intermediate";
              break;
            case 2:
              bucketName = "advanced";
              break;
            case 3:
              bucketName = "expert";
              break;
            default:
              bucketName = "novice";
          }
          //@ts-ignore
          acc[skill.id].proficiencyRangeScores[bucketName].push(up.user_id);
        }
        if (up.sme_score) {
          acc[skill.id].sme_score += up.sme_score;
          acc[skill.id].count += 1;
        }
        if (up.years_exp) {
          acc[skill.id].years_exp += up.years_exp;
          acc[skill.id].yoe_count += 1;
        }
        acc[skill.id].totalSurveyed += 1;
      }
      return acc;
    },
    {}
  );
};
