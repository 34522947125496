import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../api/auth";
import SelectAutocomplete from "../../../components/common/inputs/autocompleteselect";
import { useTranslation } from "react-i18next";
import { Option } from "../../../types/misc";
import BasicButton from "../../../components/common/buttons/basicbutton";
import DraggableList from "../../../components/common/draggableList";
import { DocumentCategory } from "../../../types/documents";
import { Item } from "../../../components/common/draggableList/draggablelist";
import { OrganizationModel } from "../../../types/organization";
import { PageLoading } from "../../../components/pageloading";
import { sortByPriority } from "../../../utils/helperfunctions";
import ConfirmDialog from "../../../components/confirmdialog";
import { documentsService } from "../../../api/services/documents.service";
import { organizationService } from "../../../api/services/organization.service";

const AdminDocumentCategories: FC = () => {
  const [t] = useTranslation();
  const auth = useAuth();
  const userLogin = auth.getUser();
  const [defaultPersonCategoryId, setDefaultPersonCategoryId] =
    useState<string>();
  const [defaultPlanCategoryId, setDefaultPlanCategoryId] = useState<string>();
  const [newCategory, setNewCategory] = useState<string>("");
  const [categories, setCategories] = useState<DocumentCategory[]>([]);
  const [items, setItems] = useState<Item[]>();
  const [options, setOptions] = useState<Option[]>();
  const [deleteId, setDeleteId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCategories = async () => {
    try {
      const res = await documentsService.fetchCategories();
      const tempCategories = sortByPriority(res.data);
      setCategories(tempCategories);

      return { success: true, res };
    } catch (error) {
      console.log("Error fetching skills:", error);
      return { success: false, error };
    }
  };

  const fetchOrganization = async (id?: string) => {
    try {
      const res = await organizationService.fetchOrganization(
        id || userLogin.orgId.toString()
      );

      setDefaults(res.data);
      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  const editCategoryDefault = async (
    type: "job" | "person",
    body: { id: string }
  ) => {
    try {
      const res = await documentsService.updateDefaultDocumentCategory(
        type,
        body
      );

      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  const deleteCategory = async (body: { id: string }) => {
    try {
      const res = await documentsService.deleteCategory(body);

      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  const createCategory = async (body: { name: string }) => {
    try {
      const res = await documentsService.createCategory(body);
      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  const editCategory = async (body: { name: string; id: string }) => {
    try {
      const res = await documentsService.createCategory(body);
      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  const orderCagetories = async (sortedIds: string[]) => {
    try {
      const res = await documentsService.orderCategories({
        category_ids: sortedIds,
      });

      return { success: true, res };
    } catch (error) {
      console.log("Error fetching organization:", error);
      return { success: false, error };
    }
  };

  //fetch once on load and set categories
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await fetchCategories();
      await fetchOrganization();
      setLoading(false);
    };
    fetchData();
  }, []);

  //on fetch, update or delete, set options and items for table
  useEffect(() => {
    if (categories?.length) {
      formatOptionsAndItems();
    }
  }, [categories]);

  useEffect(() => {
    if (options?.length && items?.length && categories.length) {
      setLoading(false);
    }
  }, [options?.length, items?.length, categories.length]);

  const setDefaults = (org: OrganizationModel) => {
    setDefaultPersonCategoryId(org.doc_default_person_category.toString());
    setDefaultPlanCategoryId(org.doc_default_job_category.toString());
  };
  const formatOptionsAndItems = () => {
    const items = categories?.map((c) => ({
      name: c.name,
      id: c.id.toString(),
      is_universal: c.is_universal || false,
      priority: c.priority || null,
    }));
    setItems(items);
    const options = categories?.map((c) => ({
      value: c.id.toString(),
      label: c.name,
    }));
    setOptions(options);
  };

  //TODO not working
  const handleSelectPlanDefault = (e: Option) => {
    setLoading(true);
    editCategoryDefault("job", { id: e.value }).then(async () => {
      await fetchOrganization();
      setLoading(false);
    });
  };

  const handleSelectPersonDefault = (e: Option) => {
    setLoading(true);
    editCategoryDefault("person", { id: e.value }).then(async () => {
      await fetchOrganization();
      setLoading(false);
    });
  };

  const handleInputChange = (event: any, ele: string) => {
    setNewCategory(ele);
  };

  const handleAddNewCategory = () => {
    setLoading(true);
    createCategory({ name: newCategory }).then(async (res) => {
      await fetchCategories();
      setLoading(false);
    });
  };

  const handleDeleteCategory = (e: string) => {
    setLoading(true);
    deleteCategory({ id: e }).then(async () => {
      await fetchCategories();
      setLoading(false);
    });
  };

  const handleEditCategory = (id: string, name: string) => {
    editCategory({ name: name, id: id }).then(
      async ({ error, res, success }) => {
        if (success) {
          fetchCategories();
        } else {
          //show error to user
        }
      }
    );
  };

  const handleDropCategory = async (id: string, newIndex: number) => {
    setLoading(true);
    let tempCategories: DocumentCategory[] = categories.slice();
    const oldIndex = tempCategories.findIndex((c) => c.id.toString() === id);
    const [item] = tempCategories.splice(oldIndex, 1);
    tempCategories.splice(newIndex, 0, item);

    const sortedIds: string[] = tempCategories.map((c) => {
      return c.id.toString();
    });

    await orderCagetories(sortedIds);

    await fetchCategories();
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="tab-container">
          <div className="defaults-container">
            <div className="default-wrapper">
              <SelectAutocomplete
                width={"50%"}
                value={defaultPlanCategoryId}
                onChange={(e) => handleSelectPlanDefault(e)}
                options={options || []}
                label={t("admin.document.plan.default.label")}
              />
            </div>
            <div className="default-wrapper">
              <SelectAutocomplete
                width={"50%"}
                value={defaultPersonCategoryId}
                onChange={(e) => handleSelectPersonDefault(e)}
                options={options || []}
                label={t("admin.document.person.default.label")}
              />
            </div>
          </div>
          <div className="new-wrapper">
            {" "}
            <SelectAutocomplete
              allowEmptySelection={true}
              value={newCategory}
              backgroundColor="gray"
              placeholder={t("admin.category.add.placeholder")}
              onChange={() => {}}
              onInputChange={(event, ele) => handleInputChange(event, ele)}
              options={options || []}
              onKeyDown={handleAddNewCategory}
              disableAllOptions={true}
              groupAllOptions={true}
              groupAllOptionsLabel={t("admin.existing.categories.label")}
            />{" "}
            <BasicButton
              onClick={handleAddNewCategory}
              disabled={!newCategory || newCategory.length < 5}>
              {t("common.add")}
            </BasicButton>
          </div>
          <div className="page-divider"></div>
          {items && (
            <DraggableList
              items={items}
              onEdit={(id, name) => handleEditCategory(id, name)}
              onDelete={(e) => setDeleteId(e)}
              onDrop={(id: string, index: number) =>
                handleDropCategory(id, index)
              }
            />
          )}
        </div>
      )}
      <ConfirmDialog
        id="skill-dialog"
        displayed={deleteId !== ""}
        titleMessage="admin.delete.category.dialog.title"
        bodyMessage={"admin.delete.category.dialog"}
        cancelButtonMessage="common.cancel"
        confirmButtonMessage="common.delete"
        onConfirm={() => handleDeleteCategory(deleteId)}
        onCancel={() => setDeleteId("")}
        isSaving={loading}
      />
    </>
  );
};

export default AdminDocumentCategories;
